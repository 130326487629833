<template>
  <div class="flex column center" style="margin-bottom:4rem;">
    <div class="new_box">
      <div class="titlee">厦门市湖里区林重阳区长一行莅临考察福建历思司法鉴定所</div>
      <p class="time">2019.01.08</p>
      <p class="space_content" style="text-indent:0">
        近日，厦门市湖里区林重阳区长、厦门市公安局许标旗副局长、分局党委委员办公室王康主任等领导莅临福建历思司法鉴定所（以下简称历思）进行考察。林区长一行参观了历思物证中心实验室，观看检验设备操作演示，并与技术人员进行了亲切的沟通交流，深入了解了检验设备操作、鉴定标准应用以及鉴定实际案例等详细情况。
      </p>

      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/20-1.png')">
      </div>

      <p class="space_content" style="text-indent:0">
        在座谈会上，林区长听取了福建历思司法鉴定所的发展情况及禁毒支撑工作汇报，高度评价了历思在司法鉴定领域所取得的成就，对历思物证中心建设的发展定位、研究方向、人才培养、运行管理等方面给予充分肯定。
      </p>
      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/20-2.png')">
      </div>
      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/20-3.png')">
      </div>

      <p class="space_content" style="text-indent:0">
        历思承担了当前福建省主要涉毒案件鉴定的相关工作，所提供的毛发检测等领先项目和综合鉴定服务深受全国各地欢迎，已为湖里禁毒行动提供毛发鉴定技术并优先进行服务保障。目前国家层面鼓励建立先进的禁毒工作手段与科学的评价体系，历思向林区长等领导表示愿意发挥所长，从毛发禁毒、污水禁毒、智慧禁毒等多方面、多专业进行探索与支撑，为湖里多做贡献。
      </p>
      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/20-4.png')">
      </div>

      <p class="space_content" style="text-indent:0">
        林区长称赞了历思低调务实的作风，希望未来历思要务实但不要过于低调，要加强宣传工作，提高鉴定中心的社会影响力，争取做厦门市司法鉴定行业的标杆，成为湖里区高端法律服务业的亮点。林区长、许副局长提出，希望历思发挥在毒物鉴定与环境鉴定方面的优势，与湖里区共同开展智慧禁毒项目和环境情报项目，成为政府决策的好帮手，湖里区将进行大力支持。
      </p>
      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/20-5.png')">
      </div>


    </div>
    <div class="flex wrap .col442" style="width:100%;text-align:left;margin-top:1rem">
      <div style="margin-bottom:1rem" class="col4 flex  start1">
        <div class="flex" style="margin-right:10%">
          <p style="white-space:nowrap">上一篇：</p>
          <router-link class="a" :to="'/news/lsnews/19'"><p class="ellipsis1">厦门市司法局叶局长一行调研指导福建历思司法鉴定所</p>
          </router-link>
        </div>
      </div>
      <div style="margin-bottom:1rem" class="col4 flex  start1">
        <div class="flex">
          <p style="white-space:nowrap">下一篇：</p>
          <router-link class="a" :to="'/news/lsnews/21'"><p class="ellipsis1">典型案例来了！高处坠物工伤事故的伤残等级鉴定</p>
          </router-link>

        </div>
      </div>
      <div class="col2 flex  end1 center">
        <router-link class="a" style="color:#fff" :to="'/news/lsnews'">
          <el-button type="primary">返回列表</el-button>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: {}
    }
  },
}
</script>
<style>
.redText {
  background: red;
  color: white;
  font-size: 20px;
  font-weight: 600;
}

.titleText {
  font-size: 20px;
  font-weight: 600;
}
</style>
